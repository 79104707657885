import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {IUser} from '../application/data-types'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {HelperService} from '@sparbanken-syd/sparbanken-syd-bankid'

const ACCESS_TOKEN_NAME = 'blanco-admin-at'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */

export interface SpbConfiguration {
  /**
   * For the lazy ones, just check the admin status
   */
  admin: boolean

  /**
   * The access token for those who need it.
   */
  token?: string

  /**
   * The current user, if any
   */
  currentUser?: IUser
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: BehaviorSubject<SpbConfiguration> = new BehaviorSubject<SpbConfiguration>({admin: false})

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.isLoggedIn()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    this.setToken(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string | null): void {
    const payload = HelperService.GetTokenPayload(token)
    if (!payload) {
      return this.resetToken()
    }

    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)

    this.configState$.next({
      admin: payload.roles.indexOf('blancoAdmin') !== -1,
      token
    })

  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): void {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.configState$.next({admin: false})
  }
}
