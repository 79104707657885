import {BrowserModule} from '@angular/platform-browser'
import {LOCALE_ID, NgModule} from '@angular/core'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {SpbCommonModule} from './common/common.module'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import '@angular/common/locales/global/fr'
import {HttpsInterceptor} from './application/https-interceptor.service'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SpbCommonModule,
    ThemeModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

