import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CommonRoutingModule} from './common-routing.module'
import {ReactiveFormsModule} from '@angular/forms'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatButtonModule} from '@angular/material/button'
import {MatInputModule} from '@angular/material/input'

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    WaitDialogComponent,
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    WaitDialogComponent,
  ],
})
export class SpbCommonModule {
}
